<template>
    <el-scrollbar class="education-wrapper" :native="false">
        <div class="steps-wrapper">
            <div class="step-item" :class="{current: [1, 2].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">1</div>
                <div class="step-title">创建运营模板</div>
            </div>
            <div class="step-item" :class="{current: [2].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">2</div>
                <div class="step-title">{{createMessage}}</div>
            </div>
        </div>
        <div class="create-content" v-if="stepActive === 1">
            <el-form :model="opModuleForm" :rules="opModuleRules" ref="opModuleForm" label-width="120px">
                <el-form-item label="运营推广名称" prop="name">
                    <el-input v-model="opModuleForm.name" placeholder="4-30个字" clearable style="width: 380px"></el-input>
                </el-form-item>
                <el-form-item label="店铺选品" prop="shop_selection_id">
                    <el-select v-model="opModuleForm.shop_selection_id" placeholder="请选择店铺选品" clearable style="width: 380px;margin-right: 10px;" @clear="clearShop_selection">
                        <el-option v-for="shop_selectionItem in shop_selectionList" :key="shop_selectionItem.id" :label="shop_selectionItem.name" :value="shop_selectionItem.id"></el-option>
                    </el-select>
                    <el-input v-model.number="opModuleForm.shop_selection_percent" oninput="value=value.replace(/[^\d]/g,'')" placeholder="设置分值比例" style="width: 120px;margin-right: 10px;" @change="inputVal"></el-input>%
                </el-form-item>
                <el-form-item label="店铺营销" prop="shop_marketing_id">
                    <el-select v-model="opModuleForm.shop_marketing_id" placeholder="请选择店铺营销" clearable style="width: 380px;margin-right: 10px;" @clear="clearShop_marketing">
                        <el-option v-for="shop_marketingItem in shop_marketingList" :key="shop_marketingItem.id" :label="shop_marketingItem.marketing_name" :value="shop_marketingItem.id"></el-option>
                    </el-select>
                    <el-input v-model.number="opModuleForm.shop_marketing_percent" placeholder="设置分值比例" oninput="value=value.replace(/[^\d]/g,'')" style="width: 120px;margin-right: 10px;" @change="inputVal"></el-input>%
                </el-form-item>
                <el-form-item label="搜索广告" prop="search_ad_id">
                    <el-select v-model="opModuleForm.search_ad_id" placeholder="请选择搜索广告" clearable style="width: 380px;margin-right: 10px;" @clear="clearSearch_ad">
                        <el-option v-for="search_adItem in search_adList" :key="search_adItem.id" :label="search_adItem.name" :value="search_adItem.id"></el-option>
                    </el-select>
                    <el-input v-model.number="opModuleForm.search_ad_percent" placeholder="设置分值比例" oninput="value=value.replace(/[^\d]/g,'')" style="width: 120px;margin-right: 10px;" @change="inputVal"></el-input>%
                </el-form-item>
                <el-form-item label="展位广告" prop="booth_ad_id">
                    <el-select v-model="opModuleForm.booth_ad_id" placeholder="请选择搜索广告" clearable style="width: 380px;margin-right: 10px;" @clear="clearBooth_ad">
                        <el-option v-for="booth_adItem in booth_adList" :key="booth_adItem.id" :label="booth_adItem.name" :value="booth_adItem.id"></el-option>
                    </el-select>
                    <el-input v-model.number="opModuleForm.booth_ad_percent" placeholder="设置分值比例" oninput="value=value.replace(/[^\d]/g,'')" style="width: 120px;margin-right: 10px;" @change="inputVal"></el-input>%
                </el-form-item>
            </el-form>
        </div>
        <div class="create-content" v-if="stepActive === 2">
            <div class="success-content">
                <i class="iconfont">&#xe730;</i>
                <p class="text">{{createMessage}}</p>
            </div>
        </div>
        <div class="bottom-btn">
            <el-button @click="prevBtn(stepActive)"  v-if="stepActive === 1">上一步</el-button>
            <el-button @click="prevBtn(stepActive)"  v-if="stepActive === 2">返回</el-button>
            <el-button class="btn-blue" @click="nextBtn('opModuleForm')"  v-if="stepActive === 1">下一步</el-button>
        </div>
    </el-scrollbar>
</template>

<script>
    import _ from "underscore";

    export default {
        name: "CreateOperationModule",
        data() {
            return {
                stepActive: 1,
                createMessage: '创建成功',
                //创建模板表单数据
                opModuleForm: {
                    name: '',
                    shop_selection_id: '',
                    shop_selection_Id: '',
                    shop_selection_name: '',
                    shop_selection_percent: null,
                    shop_marketing_id: '',
                    shop_marketing_Id: '',
                    shop_marketing_name: '',
                    shop_marketing_percent: null,
                    search_ad_id: '',
                    search_ad_Id: '',
                    search_ad_name: '',
                    search_ad_percent: null,
                    booth_ad_id: '',
                    booth_ad_Id: '',
                    booth_ad_name: '',
                    booth_ad_percent: null
                },
                opModuleRules: {
                    name: [
                        { required: true, message: '请输入运营推广名称', trigger: 'blur' },
                        { min: 4, max: 100, message: '长度在 4 到 100 个字', trigger: 'blur' }
                    ]
                },
                //模板内容
                op_module: [],
                //店铺选品列表
                shop_selectionList: [],
                //店铺营销列表
                shop_marketingList: [],
                //搜索广告列表
                search_adList: [],
                //展位广告列表
                booth_adList: [],
                //运营模板id
                operationId: Number(this.$route.query.id) || 0
            }
        },
        computed: {
            totalScore() {
                let shopSelectionScore = Number(this.opModuleForm.shop_selection_percent) || 0;
                let shopMarketingScore = Number(this.opModuleForm.shop_marketing_percent) || 0;
                let searchAdScore = Number(this.opModuleForm.search_ad_percent) || 0;
                let boothAdScore = Number(this.opModuleForm.booth_ad_percent) || 0;
                if (shopSelectionScore > -1 || shopMarketingScore > -1 || searchAdScore > -1 || boothAdScore > -1) {
                    return shopSelectionScore + shopMarketingScore + searchAdScore + boothAdScore;
                } else {
                    return 0
                }
            }

        },
        mounted() {
          this.getShopSelectionList();
          this.getShopMarketingList();
          this.getSearchAdList();
          this.getBoothAdList();
          this.editOpModule();
        },
        methods: {
            //设置分值比例必须大于0
            inputVal(val) {
                if (Number(val) === 0) {
                    this.$message.warning('设置分值比例必须大于0！');
                    return;
                }
            },
            //获取店铺选品列表
            getShopSelectionList() {
                this.$http.axiosGet(this.$api.storeSelection, (res) => {
                    if (res.code === 200) {
                        this.shop_selectionList = res.data.data;
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //获取店铺营销列表
            getShopMarketingList() {
                let param = {
                    type: 'all'
                }
                this.$http.axiosGetBy(this.$api.showStoreMarket, param, (res) => {
                    if (res.code === 200) {
                        this.shop_marketingList = res.data;
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //获取搜索广告列表
            getSearchAdList() {
                this.$http.axiosGet(this.$api.searchAdsList, (res) => {
                    if (res.code === 200) {
                        this.search_adList = res.data;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //获取展位广告列表
            getBoothAdList() {
                this.$http.axiosGet(this.$api.booth, (res) => {
                    if (res.code === 200) {
                        this.booth_adList = res.data.data;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //编辑
            editOpModule() {
                if (this.operationId) {
                    this.$http.axiosGetBy(this.$api.showOperationList, {id: this.operationId, type: 'all'}, (res) => {
                        if (res.code === 200) {
                            this.opModuleForm.name = res.data[0].name;
                            for (let i = 0; i < res.data[0].extend.length; i++) {
                                //店铺选品
                                if (parseInt(res.data[0].extend[i].op_module_type) === 1) {
                                    this.opModuleForm.shop_selection_Id = res.data[0].extend[i].id;
                                    this.opModuleForm.shop_selection_id = res.data[0].extend[i].op_module_id;
                                    this.opModuleForm.shop_selection_percent = res.data[0].extend[i].op_module_percent;
                                }
                                //店铺营销
                                if (parseInt(res.data[0].extend[i].op_module_type) === 2) {
                                    this.opModuleForm.shop_marketing_Id = res.data[0].extend[i].id;
                                    this.opModuleForm.shop_marketing_id = res.data[0].extend[i].op_module_id;
                                    this.opModuleForm.shop_marketing_percent = res.data[0].extend[i].op_module_percent;
                                }
                                //搜索广告
                                if (parseInt(res.data[0].extend[i].op_module_type) === 3) {
                                    this.opModuleForm.search_ad_Id = res.data[0].extend[i].id;
                                    this.opModuleForm.search_ad_id = res.data[0].extend[i].op_module_id;
                                    this.opModuleForm.search_ad_percent = res.data[0].extend[i].op_module_percent;
                                }
                                //展位广告
                                if (parseInt(res.data[0].extend[i].op_module_type) === 4) {
                                    this.opModuleForm.booth_ad_Id = res.data[0].extend[i].id;
                                    this.opModuleForm.booth_ad_id = res.data[0].extend[i].op_module_id;
                                    this.opModuleForm.booth_ad_percent = res.data[0].extend[i].op_module_percent;
                                }
                            }
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }
            },
            //清空
            clearShop_selection() {
                this.opModuleForm.shop_selection_id = '';
                this.opModuleForm.shop_selection_percent = '';
            },
            clearShop_marketing() {
                this.opModuleForm.shop_marketing_id = '';
                this.opModuleForm.shop_marketing_percent = '';
            },
            clearSearch_ad() {
                this.opModuleForm.search_ad_id = '';
                this.opModuleForm.search_ad_percent = '';
            },
            clearBooth_ad() {
                this.opModuleForm.booth_ad_id = '';
                this.opModuleForm.booth_ad_percent = '';
            },
            // 上一步
            prevBtn(index) {
                if (index === 1) {
                    this.$router.go(-1);
                } else {
                    this.$router.push('/admin/operatedata/index');
                }
            },
            //下一步
            nextBtn(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        //获取模板名称
                        if (this.opModuleForm.shop_selection_id) {
                            let shopSelectionName = _.find(this.shop_selectionList, {id: this.opModuleForm.shop_selection_id});
                            this.opModuleForm.shop_selection_name = shopSelectionName.name;
                            if ((this.opModuleForm.shop_selection_percent === 0) ||
                                (this.opModuleForm.shop_selection_percent === null)) {
                                this.$message.warning('店铺选品的设置分值比例必须大于0！');
                                return
                            }
                        }
                        if (this.opModuleForm.shop_marketing_id) {
                            let shopMarketingName = _.find(this.shop_marketingList, {id: this.opModuleForm.shop_marketing_id});
                            this.opModuleForm.shop_marketing_name = shopMarketingName.marketing_name;
                            if ((this.opModuleForm.shop_marketing_percent === 0) ||
                                (this.opModuleForm.shop_marketing_percent === null)) {
                                this.$message.warning('店铺营销的设置分值比例必须大于0！');
                                return
                            }
                        }
                        if (this.opModuleForm.search_ad_id) {
                            let search_adName = _.find(this.search_adList, {id: this.opModuleForm.search_ad_id});
                            this.opModuleForm.search_ad_name = search_adName.name;
                            if ((this.opModuleForm.search_ad_percent === 0) ||
                                (this.opModuleForm.search_ad_percent === null)) {
                                this.$message.warning('搜索广告的设置分值比例必须大于0！');
                                return
                            }
                        }
                        if (this.opModuleForm.booth_ad_id) {
                            let booth_adName = _.find(this.booth_adList, {id: this.opModuleForm.booth_ad_id});
                            this.opModuleForm.booth_ad_name = booth_adName.name;
                            if ((this.opModuleForm.booth_ad_percent === 0) ||
                                (this.opModuleForm.booth_ad_percent === null)) {
                                this.$message.warning('展位广告的设置分值比例必须大于0！');
                                return
                            }
                        }

                        if (this.opModuleForm.shop_selection_id || this.opModuleForm.shop_marketing_id || this.opModuleForm.search_ad_id || this.opModuleForm.booth_ad_id) {

                        } else {
                            this.$message.warning('请选择至少一个模块进行考试');
                            return;
                        }
                        if (this.opModuleForm.shop_selection_percent || this.opModuleForm.shop_marketing_percent || this.opModuleForm.search_ad_percent || this.opModuleForm.booth_ad_percent) {
                            if ((this.opModuleForm.shop_selection_percent === 0) || (this.opModuleForm.shop_marketing_percent === 0) || (this.opModuleForm.search_ad_percent === 0) || (this.opModuleForm.booth_ad_percent === 0)) {
                                this.$message.warning('设置分值比例必须大于0！');
                                return
                            }
                            if (this.totalScore !== 100) {
                                this.$message.warning('总分值比例是100%');
                                return
                            } else {
                                let formData = new FormData();
                                formData.append('name', this.opModuleForm.name);

                                //获取模板内容
                                let tmplModule_shopSelection = {};
                                let tmplModule_shopMarketing = {};
                                let tmplModule_searchAd = {};
                                let tmplModule_boothAd = {};
                                if (this.operationId) {
                                    if (this.opModuleForm.shop_selection_Id) {
                                        tmplModule_shopSelection.id = this.opModuleForm.shop_selection_Id;
                                    }
                                    if (this.opModuleForm.shop_marketing_Id) {
                                        tmplModule_shopMarketing.id = this.opModuleForm.shop_marketing_Id;
                                    }
                                    if (this.opModuleForm.search_ad_Id) {
                                        tmplModule_searchAd.id = this.opModuleForm.search_ad_Id;
                                    }
                                    if (this.opModuleForm.booth_ad_Id) {
                                        tmplModule_boothAd.id = this.opModuleForm.booth_ad_Id;
                                    }
                                }
                                //店铺选品
                                if (this.opModuleForm.shop_selection_id) {
                                    tmplModule_shopSelection.op_module_id = this.opModuleForm.shop_selection_id;
                                    tmplModule_shopSelection.op_module_type = 1;
                                    tmplModule_shopSelection.op_module_name = this.opModuleForm.shop_selection_name;
                                    tmplModule_shopSelection.op_module_percent = this.opModuleForm.shop_selection_percent;
                                }
                                //店铺营销
                                if (this.opModuleForm.shop_marketing_id) {
                                    tmplModule_shopMarketing.op_module_id = this.opModuleForm.shop_marketing_id;
                                    tmplModule_shopMarketing.op_module_type = 2;
                                    tmplModule_shopMarketing.op_module_name = this.opModuleForm.shop_marketing_name;
                                    tmplModule_shopMarketing.op_module_percent = this.opModuleForm.shop_marketing_percent;
                                }
                                //搜索广告
                                if (this.opModuleForm.search_ad_id) {
                                    tmplModule_searchAd.op_module_id = this.opModuleForm.search_ad_id;
                                    tmplModule_searchAd.op_module_type = 3;
                                    tmplModule_searchAd.op_module_name = this.opModuleForm.search_ad_name;
                                    tmplModule_searchAd.op_module_percent = this.opModuleForm.search_ad_percent;
                                }
                                //展位广告
                                if (this.opModuleForm.booth_ad_id) {
                                    tmplModule_boothAd.op_module_id = this.opModuleForm.booth_ad_id;
                                    tmplModule_boothAd.op_module_type = 4;
                                    tmplModule_boothAd.op_module_name = this.opModuleForm.booth_ad_name;
                                    tmplModule_boothAd.op_module_percent = this.opModuleForm.booth_ad_percent;
                                }

                                if (tmplModule_shopSelection.op_module_percent > 0) {
                                    this.op_module.push(tmplModule_shopSelection);
                                }
                                if (tmplModule_shopMarketing.op_module_percent > 0) {
                                    this.op_module.push(tmplModule_shopMarketing);
                                }
                                if (tmplModule_searchAd.op_module_percent > 0) {
                                    this.op_module.push(tmplModule_searchAd);
                                }
                                if (tmplModule_boothAd.op_module_percent > 0) {
                                    this.op_module.push(tmplModule_boothAd);
                                }
                                formData.append('operation_data', JSON.stringify(this.op_module));
                                console.log('this.op_module',this.op_module)

                                if (this.operationId) {
                                    formData.append('id', this.operationId);
                                    this.$http.axiosPost(this.$api.saveOperation, formData, (res) => {
                                        if (res.code === 200) {
                                            this.stepActive = 2;
                                            this.createMessage = '编辑成功！';
                                        } else {
                                            this.$message.warning(res.msg);
                                        }
                                    }, (err) => {
                                        console.log(err);
                                    })
                                } else {
                                    this.$http.axiosPost(this.$api.saveOperation, formData, (res) => {
                                        if (res.code === 200) {
                                            this.stepActive = 2;
                                            this.createMessage = '创建成功！';
                                        } else {
                                            this.$message.warning(res.msg);
                                        }
                                    }, (err) => {
                                        console.log(err);
                                    })
                                }
                            }
                        } else {
                            this.$message.warning('请在相应的模块设置分值比例，总分值比例是100%');
                            return;
                        }
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .steps-wrapper {
        margin-top: 34px;
    }
    .education-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        /deep/ .el-scrollbar__view {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        .education-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
        .create-content {
            margin: 60px 0 30px;
            height: calc(100% - 56px);
            .handel-keyword-btn {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .btn-red-key {
                    color: #EB6547;
                    border-color: #EB6547;
                    padding: 7px 30px;
                    &:focus, &:hover {
                        color: #f78989;
                        border-color: #f78989;
                        background-color: #fff;
                    }
                }
                .btn-blue-key {
                    color: #1122D8;
                    border-color: #1122D8;
                    padding: 7px 29px;
                    &:focus, &:hover {
                        color: #747eea;
                        border-color: #747eea;
                        background-color: #fff;
                    }
                }
            }
            .success-content {
                margin-top: 155px;
                text-align: center;
                .iconfont {
                    color: #1FD18C;
                    font-size: 52px;
                }
                .text {
                    font-size: 24px;
                    margin: 20px 0 0;
                    font-weight: bold;
                }
            }
        }
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
    .bottom-btn {
        text-align: center;
    }
</style>